body,
html {
    height: 100%;
    margin: 0;   
    overflow: hidden;
    font-weight: lighter;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Thasadith', sans-serif;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: linear-gradient(
        rgba(26, 26, 28, 0.8), 
        rgba(26, 26, 28, 0.8)),
        url(./assets/background1.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.9;
    animation: transitionIn 1.00s;
}

.animated{
    animation: transitionIn 1.00s;
}

@keyframes transitionIn{
    from{
        opacity:0;
        transform: rotateX(-10deg);
    }

    to{
        opacity: 1;
        transform: rotateX(0);
    }
}

::-webkit-scrollbar{
    width: 0px;
}

.content {
    font-weight: lighter;
    text-align: center;
    width:100vw;  
    height: 85vh;
    vertical-align: middle;
    display: table-cell;  
    padding-top: 0vh;
    color: whitesmoke;
    font-weight: 100;
    animation: transitionIn 2.00s;
}

.big{
    font-size:296%;
    padding: 1px;
    margin: 1px;
    font-weight: bold;
}
.big1{
    font-size:390%;
    font-weight: lighter;
    padding: 1px;
    margin: 1px;
    font-family: 'Rajdhani', sans-serif;
    
    
}
.medium{
    font-size:200%;
    padding: 1px;
    margin: 1px;
    font-weight: bold;
}

.small{
    font-size:140%;
    padding: 1px;
    margin: 1px;
    text-transform: capitalize;
    font-weight: 500;
}

#forgot,
#acsvc,
#loginlink{
    cursor: pointer;
}

.search{
    text-align: center;
}

.form-control-borderless {
    border: none;
    width: 300px;
    border-radius: 40px;
    margin-top: 5px;
    text-align: center;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.formhead{
    text-align:center;
    font-size:170%;
    font-weight: 900;
}

.select-box
{
    font-size: inherit;
    border: none;
    border-radius: 50px;
    padding: 0.2em 0.5em;
    outline: none;
	width: 400px;
	height: 50px;
	font-family: 'Thasadith', sans-serif;
    text-align: center;
    font-weight: 700;
    
}

/*Searching for Country.......*/
::placeholder {
    color: rgb(0, 0, 0);
    font-weight: 600;
}
.search-content
{
	font-family: 'Thasadith', sans-serif;
    font-weight: lighter;
    width:100vw;  
    height: 2vh;
    vertical-align: middle;
    display: table-cell;  
    text-align:center;
	
}

.search-box-wrapper {
    display: inline-flex;
    font-size: 17px;
	
}
.search-box-input {
    font-size: inherit;
    border: none;
    border-radius: 19em 0 0 19em;
    padding: 0.2em 0.5em;
    outline: none;
	width: 350px;
	height: 50px;
	font-family: 'Thasadith', sans-serif;
	text-align: center;
}

.search-box-input:hover,
.search-box-input:focus {
    border-color: #006064;
}
.search-box-button {
    font-size: inherit;
    border: none;
    border-radius: 0 19em 19em 0;
    background-color: #3949AB;
    border-left: 0;
    padding: 0 0.75em;
    color: white;
    font-weight:bold;
    outline: 0;
    cursor: pointer;
}

.search-box-button:hover,
.search-box-button:focus {
    border-color: #1A237E;
    background-color: #1A237E;
	outline: none;
}

/*Navbar*/
.navbar{
    background-color: rgba(32, 35, 44, 0.5);
}

.navbar-toggler:focus, 
.navbar-toggler:active {
    outline: none;
    box-shadow: none;
}

@media only screen and (max-width: 768px) {
    
    /*Searching for Country.......*/
    .search-box-input {
        width: 270px;
        height: 50px;
    }
    .search-box-input 
    {
        width: 270px;
        height: 45px;
    }

    .navbar
    {
        background-color: #171B27;
    }

    .big{
        text-align:center;
        font-size:270%;
    }
    .big1{
        font-size:300%;
        font-weight: lighter;
        
    }

    .medium{
        text-align:center;
        font-size:190%;
    }
}

.navbar-brand{
    cursor: pointer;
}